import { PictureElementList } from '../PictureElementList'
import type { INotificationItem } from '../../../contexts/NotificationsContext'
import { SVGIcon } from '../SVGIcon'
import { theme } from '../../../styles'

export function NotificationItem({
  pictures,
  title,
  date,
  hasMultiLive,
  hasVideo,
  link,
}: INotificationItem): JSX.Element {
  const formattedTime: string = new Date(date).toLocaleTimeString('fr-FR', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: false,
  })

  return (
    <>
      <>
        <time className="NotificationItem__Date" dateTime={`${date}`}>
          {formattedTime}
        </time>
        {pictures?.elementList?.length ? (
          <figure className="NotificationItem__Picture">
            {hasVideo ? (
              <SVGIcon name="play-circle" secondaryColor={theme.cssVars.white} size={32} />
            ) : null}
            <PictureElementList {...pictures} alt={title} />
          </figure>
        ) : null}
        <a className="NotificationItem__Title" href={link}>
          {hasMultiLive && <SVGIcon name="direct" size={13} />}
          {title}
        </a>
      </>

      <style jsx>{`
        .NotificationItem__Date {
          font-family: ${theme.cssVars.overpass};
          font-weight: 100;
          font-size: 36px;
          line-height: 122%;
          /* Layout*/
          display: block;
          margin-bottom: 20px;
        }

        .NotificationItem__Picture {
          position: relative;
          padding-top: 56.25%; // ratio of 16/9
          width: 100%;
          margin-bottom: 12px;
          border-radius: 4px;
          overflow: hidden;
          z-index: 0;
        }

        .NotificationItem__Picture :global(img) {
          position: absolute;
          top: 0;
          left: 0;
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .NotificationItem__Picture :global(svg) {
          position: absolute;
          bottom: 16px;
          left: 16px;
          z-index: 2;
        }

        .NotificationItem__Title {
          color: ${theme.cssVars.white};
          font-size: 25px;
          font-weight: 800;
          line-height: 129%;
        }

        .NotificationItem__Title :global(svg) {
          margin-right: 5px;
          margin-bottom: 2px;
          display: inline;
        }

        .NotificationItem__Title::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      `}</style>
    </>
  )
}
